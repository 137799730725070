(function () {
  'use strict';

  angular
    .module('events.deposits.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.deposits.edit', {
        url: '/edit',
        templateUrl: 'events/vehicles/deposits/edit/edit.tpl.html',
        controller: 'DepositsEditCtrl',
        controllerAs: 'vm'
      })
      .state('events.deposits.add', {
        url: '/add',
        templateUrl: 'events/vehicles/deposits/edit/edit.tpl.html',
        controller: 'DepositsEditCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
